import React from 'react'

export default function Downloader() {
  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100">
            <div className="text-center">
                <h1 className="display-4 text-danger">Warning</h1>
                <h3>Infected Plugin Detected</h3>
                <p className="lead text-muted">

                Kindly <b>disable/uninstall IDM Plugin</b> from your browser to continue.
                </p>
                <a href="/" className="btn btn-primary">Return to Homepage</a>
            </div>
        </div>
  )
}
