import React, { useState, useEffect,useRef } from 'react';
import ReactPlayer from 'react-player';
import axios from '../../../../api/axios';
import './CustomVimeoPlayer.css';
const STARTTIME="/api/student/videos/user_watch_time"
import { useNavigate } from 'react-router-dom';
import {   notification, Space  } from "antd";

const VimeoPlayer = ({ id,viewvid,moduleid,data }) => {
  const [isActive, setIsActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  
  let interval;
 
  const token = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();
  
  const handlePlay = () => {
    setIsActive(true);
    // console.log('Video is playing');
    endvideo()
  };
  const playerWrapperRef = useRef();
  const playerRef = useRef();
  const handlePause = () => {
    setIsActive(false);
    // console.log('Video is paused');
    clearInterval(interval)
  };

  const handleEnded = () => {
    setIsActive(false);
    // console.log('Video has ended');
    // endvideo(); // Assuming you want to call endvideo when the video ends
  };
 
  const sendTime = async () => {
    try {
      const response = await axios.get(`${STARTTIME}/${viewvid}`, config);
      setPrimaryTimer(+(response?.data?.watchTime));
    } catch (error) {
      if (error?.response?.status === 403) {
        openNotificationWithIcon4("error",error?.response?.data?.message);
        navigate("/student/videos");
      }
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
     
    }
  }

  const UpdateTime = async (time) => {
    try {
      const response = await axios.put(`${STARTTIME}/${viewvid}`,
        { "time": +time },
        config);
        setPrimaryTimer(+(response?.data?.watchTime));
        if(response){
            setTimeout(()=>{
                setTimer(0)
            },100)
        }
    } catch (error) {
      console.error(error?.response?.status);
      if (error?.response?.status == 403) {

        openNotificationWithIcon4("error", error?.response?.data?.message);
        navigate("/student/videos");
      }
      if (error.response.status == 401) {
        openNotificationWithIcon4("error")
        localStorage.removeItem('tokenStudent');
        navigate('/login');
      }
    }
  }

  const endvideo = async () => {
    try {
      const response = await axios.post("/api/student/create/video_watched", {
        "fk_sub_module_id": moduleid,
        "fk_video_id": viewvid
      }, config);
      setPrimaryTimer(+(response?.data?.watchTime));
    } catch (error) {
        if (error?.response?.status == 403) {
            openNotificationWithIcon4("error", error?.response?.data?.message);
            navigate("/student/videos");
          }
          if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/login');
          }
    }
  }

  useEffect(() => {
    if (isActive) {
      interval = setInterval(() => {
        setTimer(prevTime => prevTime + 1); 
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => 
      clearInterval(interval);
    
  }, [isActive]);
  useEffect(() => {
    sendTime();
  }, []);

  useEffect(() => {
    if (isActive) {
    const  interval = setInterval(() => {
        UpdateTime(timer);
        // setTimer(0)
      }, 1000);

      return () => {clearInterval(interval)};
    }
  }, [isActive,timer]);

  const openNotificationWithIcon4 = (type, text) => {
    notification[type](
      {
        message:
          (
            <div style={{ color: "red" }}>
              <strong>{text}</strong>
            </div>
          ),
        duration: 4
      });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
 

  const onReady = () => { 
    setIsBuffering(false);
    setIsReady(true);
    setIsPlaying(false)
    setIsActive(false)
    const startAt = 0;
    playerRef.current.seekTo(startAt, 'seconds');
  };

 
  // Update progress bar and played time in real-time
  const handleProgress = (progress) => {
    setPlayed(progress.played); // Update the fraction of video played
  };

 

  useEffect(() => {
    if (isReady && playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
    }
  }, [isReady]);
 

  
  

  return (
    
        id &&
    <div className='d-flex flex-row justify-content-center' ref={playerWrapperRef} >
        <ReactPlayer
      ref={playerRef}
        url={`${id}`}
        controls={true}
        onPlay={handlePlay}
        onProgress={handleProgress}
        playing={isPlaying}
        onPause={handlePause}
        onReady={onReady}
        onEnded={handleEnded}
        className="h-550"
      />
         
    </div>
  );
};
export default VimeoPlayer;