import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from '../../../../api/axios'
import { notification, Space } from "antd";
const logoutAllDevice = "/api/logout-devices"
export default function Error409_1() {
    const navigate = useNavigate()

    const handleLogout = ()=>{
       let email =  localStorage.getItem('lastemail');
       let password = atob(localStorage.getItem('lastpassword'));
        try{
            let login =  axios.post(logoutAllDevice, { email: email, password: password})
            if(login){
                localStorage.removeItem('lastemail')
                localStorage.removeItem('lastpassword')
                openNotificationWithIcon3("info", "Logged out from all devices successfully");
                navigate('/')
            }
        }catch(e){
            console.log(e)
        }
    }
    const openNotificationWithIcon3 = (type, text) => {
        notification[type]({
          message: (
            <div style={{ color: "blue" }}>
              <strong>{text}</strong>
            </div>
          ),
          duration: 2,
        });
      };
  return (
    <div style={{
        width:'100vw',
        height:'100vh',
        backgroundColor:'#ef4444',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        color:'white'
    }}>

        <FaExclamationTriangle style={{fontSize:'12rem'}} />
        <p style={{
            fontSize:'2.5rem',
            fontWeight:'bold',
            textAlign:'center'
        }}>WARNING !,<br></br>  You are already logged in another device,<br/> Logout first to continue...</p>

        <div style={{
            display:'flex',
            justifyContent:'center',
            marginLeft:'10px',
            marginRight:'10px',
        }}>
            <button onClick={(()=>navigate('/'))} className='btn btn-info mx-3 btn-lg '>Cancel</button>

            <button onClick={handleLogout} className='btn btn-warning mx-3 btn-lg'>Logout from all other device ? </button>

        </div>
                </div>
  )
}
