import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../footer";
// import Modal from "react-bootstrap/Modal";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../adminHeader";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import Pagination from "../../pages/course/addCourse/Pagination";
import { Input, Tooltip,Modal } from "antd";
import AdminInstructorSidebar from "../adminSidebar";
import { notification, Space, } from "antd";
import PaginationMod from "../../pages/course/addCourse/PaginationMod";
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
export default function AdminUserLogged() {
    const USERLOGGED = "/api/admin/user-logged";
    const USERDETAILS = "/api/admin/user-logged";
    const USERDETAILSSTATUS = "/api/admin/user-logged";
    const [text, settext] = useState("");
      const [show, setShow] = useState(false);
      const [showTest, setShowTest] = useState(false);
      const [loading, setLoading] = useState(false);
      const [message, setmessage] = useState("No Data");
      const [currentPage, setCurrentPage] = useState(1);
      const [last_page, setLast_page] = useState("");
      const [listModule, setListModule] = useState(null);
      const [modalData, setModalData] = useState(null);
      const [data, setData] = useState([]);
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    const token = localStorage.getItem("token");
      useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/admin/login");
        }
      }, []);
       useEffect(()=>{
          localStorage.removeItem("items")
          localStorage.removeItem("pagination")
        },[])
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
         const openNotificationWithIcon2 = (type,message) => {
            notification[type]({
              message: (
                <div style={{ color: "red" }}>
                  <strong>{message} </strong>
                </div>
              ),
              duration: 1,
            });
          };
          let rows = [];
       const fetchData = async () => {
          try {
            setLoading(true);
            const response = await axios.get(USERLOGGED, config);
            console.log(response.data.message)
            console.log(response.data.message.length)
            let datas = [];
            if(response.data.message.length !== 0){
                
                response.data.message.map((e,i)=>{
                    let sp = e.split(':')[1]
                    datas.push({
                        id:i+1,
                        uuid:sp.split('__')[0],
                        name:sp.split('__')[1],
                        email:sp.split('__')[2],
                        phone:sp.split('__')[3],
                        status:sp.split('__')[4]
                    })
                })
            }
            setData(datas)
            
            // setData(response.data.module.data);
            // setLast_page(response.data.module.last_page);
            // setListModule(response.data.module.data);
            // setListSubModule(response.data.subModule.data);
            // setCurrentPage(page);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            if (error?.response?.status === 401) {
              openNotificationWithIconSesion("error")
              localStorage.removeItem("token");
              navigate("/admin/login");
            }
            else{
              openNotificationWithIcon2("error","Internal Server Error")
            }
          }
        };
        useEffect(() => {
            fetchData()
            
        },[])

        const handleDetailClick = async (uuid) => {
            try {
              setLoading(true);
              let uid = `laravel_user_list:${uuid.uuid}__${uuid.name}__${uuid.email}__${uuid.phone}__${uuid.status}`
              const response = await axios.get(`${USERDETAILS}/${uid}`, config);
              setModalData(response.data.message); // Store the response in modalData
              setLoading(false);
              setShow(true);
            } catch (error) {
              setLoading(false);
              openNotificationWithIcon2("error", "Failed to fetch details");
            }
          };
          const handleCloseModal = () => {
            setShow(false);
          };
          const handleStatusChange = async(studentId,status)=>{
            try{
              await axios.put(`${USERDETAILSSTATUS}/${studentId}`,{
                status:status
              }, config);
              openNotificationWithIcon2("success","Status updated successfully");
              } catch (error) {
              setLoading(false);
              openNotificationWithIcon2("error", "Failed to fetch details");
            }
          }
        const columns = [
            { field: 'id', headerName: 'ID', width: 90,flex:0.1, filterable: false },
            {
              field: 'name',
              headerName: 'Name',
              width: 150,
              flex:0.3,
             
              filterable: false
            },
            {
              field: 'email',
              headerName: 'Email',
              width: 150,
              flex:0.2,
              filterable: false
            },
            {
                field: 'phone',
                headerName: 'Phone',
                width: 150,
                flex:0.2,
                filterable: false
              },
              // {
              //   field: 'status',
              //   headerName: 'Status',
              //   width: 150,
              //   flex:0.2,
              //   filterable: false,
              //   renderCell: (params) => (
              //     <Switch
              //       checked={params.row.status === "1"? true : false}
              //       onChange={() => handleStatusChange(params.row.uuid, params.row.status === "1" ? 3 : 1)}
              //       disabled={false}
              //       />
              //   )
              // },
              {
                field: 'action',
                headerName: 'Action',
                width: 150,
                flex:0.2,
                filterable: false,
                renderCell: (params) => (
                    <Button
                      variant="primary"
                      onClick={() => handleDetailClick(params.row)} // Trigger API fetch on button click
                    >
                      Detail
                    </Button>
                  ),
              },
              
          ];


          
          
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"admin_user_logged"} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"admin_user_logged"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget ttt">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>User Login Logged</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                        <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
            toolbar: {
                disableColumnMenu:true,
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            },
          }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
      />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
      title="User Details"
      visible={show}
      onCancel={handleCloseModal}
      footer={null}
      width={600}
    >   
        {/* <p>{modalData && modalData.length}</p> */}
      {modalData && modalData.length != 0 ? (
        <>
        <table className="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Device</th>
                    <th>OS</th>
                    <th>Application</th>
                    <th>Student Status</th>
                    <th>Time</th>
                </tr>
            </thead>
            <tbody>
                {
                    modalData.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.split('__')[3]}</td>
                            <td>{item.split('__')[2]}</td>
                            <td>{item.split('__')[1]}</td>
                            <td>{item.split('__')[0]}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </Modal>
    </div>
  );
}

