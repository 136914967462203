import axios from "axios";

//  const BASEURL ='https://lmscode.stellarflux.in/'
     const BASEURL ='https://lmsstagecode.stellarflux.com/'
    //   const BASEURL ='https://lmsstagecode.stellarflux.in'
    export const ENDPOINT ='https://lmsstagecode.stellarflux.com/'
export default axios.create({
    baseURL:BASEURL
    
})
